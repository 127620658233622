import React, { useState, useEffect } from 'react';
import { RefreshCw, Wifi, WifiOff } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import Alert, { AlertDescription } from './components/ui/alert';

const formatDisplayName = (symbol) => {
    return symbol
        .replace(/USDg$/, '')    // Remove 'USDg' from the end
        .replace(/USD_t$/, '')   // Remove 'USD_t' from the end
        .replace(/USD$/, '');    // Remove 'USD' from the end
};

const MT5MarketTable = () => {
    // Get selected symbols from WordPress settings
    const SELECTED_SYMBOLS = window.mt5MarketData.selectedSymbols || [];
    const [symbols, setSymbols] = useState(SELECTED_SYMBOLS);
    const [marketData, setMarketData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isConnected, setIsConnected] = useState(true);
    const [lastUpdate, setLastUpdate] = useState(null);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const [fallbackData, setFallbackData] = useState([]);

    // Get settings from WordPress
    const API_TOKEN = window.mt5MarketData.apiToken;
    const ACCOUNT_ID = window.mt5MarketData.accountId;

    // console.log('Selected Symbols:', SELECTED_SYMBOLS);
    // console.log('API Token:', API_TOKEN);
    // console.log('Account ID:', ACCOUNT_ID);

    const API_HEADERS = {
        'auth-token': API_TOKEN,
        'Content-Type': 'application/json'
    };

    // Ensure marketData uses fallback data first and then updates with fresh API data
    useEffect(() => {
        const fetchStoredData = async () => {
            try {
                const restUrl = window.mt5MarketData.restUrl || '/wp-json/';
                const response = await fetch(`${restUrl}mt5-market-data-cryptos/v1/latest`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch stored data');
                }

                const data = await response.json();
                console.log('Fetched stored data:', data);
                
                if (data.status === 'success' && Array.isArray(data.data)) {
                    setFallbackData(data.data);
                    setMarketData(data.data); // Display fetched stored data immediately
                    setIsLoading(false); // Set loading to false once we have initial data
                }
            } catch (err) {
                console.error('Error fetching stored data:', err);
                setIsLoading(false); // Ensure loading is stopped even on error
            }
        };

        fetchStoredData();
    }, []);

    // Fetch new market data only if fallbackData is loaded
    useEffect(() => {
        if (symbols.length > 0 && fallbackData.length > 0) {
            fetchMarketData(symbols);
        }
    }, [symbols, fallbackData]); // Trigger fetch when symbols or fallbackData changes


    // Fetch fresh market data from API
    const fetchMarketData = async (symbolsList) => {
        try {
            setIsLoading(true);
            
            const marketDataPromises = symbolsList.map(async (symbol) => {
                try {
                    const url = `https://mt-client-api-v1.london.agiliumtrade.ai/users/current/accounts/${ACCOUNT_ID}/symbols/${symbol}/current-candles/1d?keepSubscription=false`;
                    
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'auth-token': API_TOKEN,
                            'Content-Type': 'application/json'
                        },
                        mode: 'cors'
                    });
                    
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const candleData = await response.json();

                    if (!candleData || !isValidNumber(candleData.close)) {
                        throw new Error('Invalid candle data received');
                    }

                    // Calculate day change as percentage
                    const dayChange = candleData.open > 0 ? 
                        ((candleData.close - candleData.open) / candleData.open * 100) : 0;

                    // Calculate market cap (volume * closing price)
                    const marketCap = candleData.volume ? candleData.volume * candleData.close : 0;
                    
                    return {
                        symbol: symbol,
                        price: candleData.close,
                        market_cap: marketCap,
                        day_change: dayChange
                    };
                } catch (err) {
                    // Use fallback data if API data is invalid
                    const storedData = fallbackData.find(item => item.symbol === symbol);
                    return storedData || null;
                }
            });

            const results = await Promise.all(marketDataPromises);
            const validResults = results.filter(Boolean);

            if (validResults.length === 0) {
                // If no valid API data, use fallback data
                setMarketData(fallbackData);
                throw new Error('No valid data received for any symbol');
            }

            setMarketData(validResults);
            setLastUpdate(new Date());
            setIsConnected(true);
            setError(null);

            // Store only the valid API data
            const apiData = validResults.filter(item => {
                const storedData = fallbackData.find(stored => stored.symbol === item.symbol);
                return !storedData || (storedData && item.price !== storedData.price);
            });

            if (apiData.length > 0) {
                storeMarketData(apiData);
            }
        } catch (err) {
            setError(`Failed to fetch market data: ${err.message}`);
            setIsConnected(false);
        } finally {
            setIsLoading(false);
        }
    };

    // Set up interval for market data updates
    useEffect(() => {
        if (symbols.length === 0) {
            return;
        }

        const interval = setInterval(() => {
            fetchMarketData(symbols);
        }, 5000); // Update every 5 seconds

        // Cleanup interval on unmount
        return () => clearInterval(interval);
    }, [symbols]); // Depend on symbols array

    // Add helper function to check for valid numbers
    const isValidNumber = (value) => {
        return typeof value === 'number' && !isNaN(value) && isFinite(value);
    };

    // Calculate pagination values
    const totalPages = Math.max(1, Math.ceil((symbols?.length || 0) / rowsPerPage));
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentSymbols = Array.isArray(symbols) ? symbols.slice(startIndex, endIndex) : [];

    const renderSparkline = (trend, dayChange) => {
        if (!trend || trend.length === 0) return null;

        const width = 100;
        const height = 20;
        const padding = 2;

        // Normalize values for SVG viewport
        const min = Math.min(...trend);
        const max = Math.max(...trend);
        const range = max - min;

        const normalizeY = (value) => {
            return height - padding - ((value - min) / range) * (height - 2 * padding);
        };

        const points = trend.map((value, index) => {
            const x = (index * (width - 2 * padding)) / (trend.length - 1) + padding;
            const y = normalizeY(value);
            return `${x},${y}`;
        }).join(' ');

        const lineColor = dayChange >= 0 ? '#22c55e' : '#ef4444';

        return (
            <svg className="w-24 h-5" viewBox={`0 0 ${width} ${height}`}>
                {/* Base line */}
                <line
                    x1={padding}
                    y1={normalizeY(trend[0])}
                    x2={width - padding}
                    y2={normalizeY(trend[0])}
                    stroke="#e5e7eb"
                    strokeWidth="0.5"
                    strokeDasharray="2,2"
                />
                {/* Trend line */}
                <polyline
                    points={points}
                    fill="none"
                    stroke={lineColor}
                    strokeWidth="1"
                />
            </svg>
        );
    };

    const renderChange = (change) => {
        if (change === null) return '-';
        const isPositive = change > 0;
        const color = isPositive ? 'text-green-500' : 'text-red-500';
        const arrow = isPositive ? '↑' : '↓';
        return (
            <span className={color}>
                {arrow} {Math.abs(change).toFixed(2)}%
            </span>
        );
    };

    const formatPrice = (price, product) => {
        // Convert price to number if it's a string or handle invalid values
        const numericPrice = typeof price === 'string' ? parseFloat(price) : price;
        
        // Check if price is a valid number
        if (!numericPrice || isNaN(numericPrice)) return '-';
        
        // Format based on instrument type
        if (product === 'GBP/USD') {
            return numericPrice.toFixed(4);
        } else if (product === 'XAUUSD') {
            return numericPrice.toFixed(2);
        } else if (['WTI', 'BRENT'].includes(product)) {
            return numericPrice.toFixed(2);
        } else {
            return numericPrice.toFixed(2);
        }
    };

    // Pagination controls component
    const Pagination = () => (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
                <button
                    onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                >
                    Previous
                </button>
                <button
                    onClick={() => setCurrentPage(page => Math.min(totalPages, page + 1))}
                    disabled={currentPage === totalPages}
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                >
                    Next
                </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{startIndex + 1}</span> to{' '}
                        <span className="font-medium">{Math.min(endIndex, symbols.length)}</span> of{' '}
                        <span className="font-medium">{symbols.length}</span> results
                    </p>
                </div>
                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <button
                            onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
                            disabled={currentPage === 1}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50"
                        >
                            Previous
                        </button>
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => setCurrentPage(index + 1)}
                                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                                    currentPage === index + 1
                                        ? 'z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                        : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                }`}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            onClick={() => setCurrentPage(page => Math.min(totalPages, page + 1))}
                            disabled={currentPage === totalPages}
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50"
                        >
                            Next
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );

    // Inside the fetchMarketData function, after setting the marketData state:
    const storeMarketData = async (newData) => {
        try {
            const restUrl = window.mt5MarketData.restUrl || '/wp-json/';
            const nonce = window.mt5MarketData.nonce;

            // Format the data to match database columns exactly
            const dataToStore = newData.map(item => ({
                symbol: item.symbol,
                price: parseFloat(item.price) || 0,
                market_cap: parseFloat(item.market_cap) || 0,
                day_change: parseFloat(item.day_change) || 0
            }));

            // Log the exact data being sent
            console.log('Data being sent to store:', {
                endpoint: `${restUrl}mt5-market-data-cryptos/v1/store`,
                data: dataToStore
            });

            const response = await fetch(`${restUrl}mt5-market-data-cryptos/v1/store`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-WP-Nonce': nonce
                },
                body: JSON.stringify({
                    marketData: dataToStore
                })
            });

            const responseData = await response.json();
            console.log('Server response:', responseData);

            if (!response.ok) {
                console.error('Storage failed:', responseData);
                return false;
            }

            return true;
        } catch (err) {
            console.error('Error storing market data:', err);
            return false;
        }
    };

    const renderWeeklyChange = (change) => {
        // More strict checking specifically for weekly change
        if (change === null || change === undefined || isNaN(change) || !isFinite(change)) {
            console.log('Invalid weekly change value:', change);
            return '-';
        }
        
        // Ensure we're working with a number
        const numericChange = parseFloat(change);
        if (isNaN(numericChange)) {
            console.log('Failed to parse weekly change:', change);
            return '-';
        }

        const isPositive = numericChange > 0;
        const color = isPositive ? 'text-green-500' : 'text-red-500';
        const arrow = isPositive ? '↑' : '↓';
        return (
            <span className={color}>
                {arrow} {Math.abs(numericChange).toFixed(2)}%
            </span>
        );
    };

    return (
        <Card className="w-full">
            {/* <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle>Crypto Market Data</CardTitle>
                <div className="flex items-center gap-4 text-sm text-gray-500">
                    {isConnected ? (
                        <Wifi className="h-4 w-4 text-green-500" />
                    ) : (
                        <WifiOff className="h-4 w-4 text-red-500" />
                    )}
                    {lastUpdate && (
                        <span>
                            Last update: {lastUpdate.toLocaleTimeString()}
                        </span>
                    )}
                    <RefreshCw 
                        className={`h-4 w-4 cursor-pointer ${isLoading ? 'animate-spin' : ''}`}
                        onClick={() => fetchMarketData(symbols)}
                    />
                </div>
            </CardHeader> */}
            <CardContent>
                {error && (
                    <Alert variant="destructive" className="mb-4">
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}
                {symbols.length === 0 ? (
                    <Alert className="mb-4">
                        <AlertDescription>
                            No crypto symbols selected. Please configure the symbols in MT5 Market Data Cryptos Settings.
                        </AlertDescription>
                    </Alert>
                ) : (
                    <div className="short-trading-table__tab tab active">
                        <table className="short-trading-table__table">
                            <thead>
                                <tr className="border-b">
                                    <th className="text-left p-2">Name</th>
                                    <th className="text-right p-2">Price</th>
                                    {/* <th className="text-right p-2">Market Cap</th> */}
                                    <th className="text-right p-2">24H Change</th>
                                </tr>
                            </thead>
                            <tbody>
                                {symbols.map((symbol) => {
                                    const data = marketData.find(item => item.symbol === symbol) || 
                                                fallbackData.find(item => item.symbol === symbol);
                                    return (
                                        <tr key={symbol} className="border-b hover:bg-gray-50">
                                            <td className="p-2 font-medium">
                                                {formatDisplayName(symbol)}
                                            </td>
                                            <td className="text-right p-2">
                                                {formatPrice(data?.price, symbol)}
                                            </td>
                                            {/* <td className="text-right p-2">
                                                {data?.market_cap ? 
                                                    new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        notation: 'compact',
                                                        maximumFractionDigits: 2
                                                    }).format(data.market_cap) : '-'}
                                            </td> */}
                                            <td className="text-right p-2">
                                                {renderChange(data?.day_change)}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
                {/* {symbols.length > 0 && <Pagination />}
                <p className="text-sm text-gray-500 mt-4">
                    Pricing is indicative. Past performance is not a reliable indicator of future results.
                </p> */}
            </CardContent>
        </Card>
    );
};

export default MT5MarketTable;
